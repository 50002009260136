import { localGetItem } from '@/utils/index';

/**
 * @desc 加载字典
 * @param key {string}
 * */
export const loadDicItem = key => {
  let dic = [];
  const dictionary = localGetItem('dictionary');
  if (dictionary?.[key]) {
    dic = dictionary?.[key]?.map(item => ({
      id: item.code,
      name: item.name,
    }));
  }
  return dic;
};

/**
 * @desc 根据code获取 name 值
 * @param code {string}
 * @param dictionary {Array}
 */
export const getNameByCode = (code, dictionary) => {
  if (dictionary) {
    return dictionary.find(item => item.id === code)?.name;
  }
  return '';
};
