<!-------------------------------------------------
description:  发票详情 发票处理表单
/**
 *@author xiaosanye 
 *@date 2023/2/11
*/
--------------------------------------------------->
<template>
  <ykc-detail-plus class="box-card">
    <div slot="header" class="clearfix">
      <span>发票处理</span>
    </div>
    <ykc-form :model="ruleForm" :rules="rules" ref="ruleForm">
      <ykc-form-item label="发票形式" prop="invoiceSituation">
        <ykc-radio
          :data="defaultData"
          :widthArr="[5, 2, 6]"
          v-model="ruleForm.invoiceSituation"
          @change="radioChange"></ykc-radio>
      </ykc-form-item>
      <ykc-form-item label="发票编号" prop="invoiceNo1">
        <ykc-input v-model="ruleForm.invoiceNo1"></ykc-input>
      </ykc-form-item>
      <ykc-form-item label="发票编号2" prop="invoiceNo2">
        <ykc-input v-model="ruleForm.invoiceNo2"></ykc-input>
      </ykc-form-item>
      <ykc-form-item v-if="ruleForm.invoiceSituation === '1'" label="快递单号" prop="courierNumber">
        <ykc-input v-model="ruleForm.courierNumber"></ykc-input>
      </ykc-form-item>
      <el-form-item>
        <ykc-button type="plain">取消</ykc-button>
        <ykc-button style="margin-left: 8px" @click="submitForm('ruleForm')">确认开票</ykc-button>
      </el-form-item>
    </ykc-form>
  </ykc-detail-plus>
</template>

<script>
  import { invoiceManagements } from '@/service/apis';
  import regexpObj from '@/utils/regexp';

  export default {
    name: 'InvoiceProcessForm',
    components: {},
    props: {
      info: { type: Object, default: () => ({}) },
      id: { type: [Number, String], default: '' },
    },
    created() {
      this.ruleForm.id = this.$props.id;
      this.ruleForm.invoiceSituation = this.$props.info.invoiceSituation;
      this.ruleForm.invoiceNo1 = this.info.invoiceNo1;
      this.ruleForm.invoiceNo2 = this.info.invoiceNo2;
    },
    watch: {
      info(newValue) {
        console.log('-> invoice form newValue', newValue);
        this.ruleForm.invoiceSituation = this.$props.info.invoiceSituation;
        this.ruleForm.invoiceNo1 = this.info.invoiceNo1;
        this.ruleForm.invoiceNo2 = this.info.invoiceNo2;
      },
    },
    data() {
      return {
        ruleForm: {
          /**
           * 申请开票id
           */
          id: '',
          /**
           * 快递单号，发票形式为1纸质发票时，必填
           */
          courierNumber: '',
          /**
           * 发票编号1
           */
          invoiceNoOne: '',
          invoiceNo1: '',
          /**
           * 发票编号2
           */
          invoiceNoTwo: '',
          invoiceNo2: '',
          /**
           * 发票形式1-纸质发票、2-电子发票
           */
          invoiceSituation: '',
        },
        defaultData: [
          { name: '纸质发票', id: '1' },
          { name: '电子发票', id: '2' },
        ],
      };
    },
    computed: {
      rules() {
        const rule2 = {
          applyId: [{ required: true, message: '请输入申请开票id', trigger: 'blur' }],
          invoiceSituation: [{ required: true, message: '请选择发票形式', trigger: 'blur' }],
          invoiceNoOne: [
            {
              required: true,
              message: regexpObj.regexp.input.accountType.errorTips.empty('发票编号1'),
              trigger: 'blur',
            },
            {
              pattern: regexpObj.regexp.input.accountType.regexp,
              message: regexpObj.regexp.input.accountType.errorTips.error('发票编号1'),
              trigger: 'blur',
            },
          ],
          invoiceNoTwo: [
            {
              required: true,
              message: regexpObj.regexp.input.accountType.errorTips.empty('发票编号2'),
              trigger: 'blur',
            },
            {
              pattern: regexpObj.regexp.input.accountType.regexp,
              message: regexpObj.regexp.input.accountType.errorTips.error('发票编号2'),
              trigger: 'blur',
            },
          ],
        };
        const rule1 = {
          ...rule2,
          courierNumber: [
            {
              required: true,
              message: regexpObj.regexp.input.orderNoType.errorTips.empty('快递单号'),
              trigger: 'blur',
            },
            {
              pattern: regexpObj.regexp.input.orderNoType.regexp,
              message: regexpObj.regexp.input.orderNoType.errorTips.error('快递单号'),
              trigger: 'blur',
            },
          ],
        };
        return this.ruleForm.invoiceSituation === '1' ? rule1 : rule2;
      },
    },
    methods: {
      submitForm() {
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            this.formValid = true;
            try {
              // todo  校验规则正确后 请求接口
              invoiceManagements
                .confirmInvoice(this.ruleForm)
                .then(res => {
                  console.log('-> confirmInvoice res', res);
                  this.$message({
                    message: '提交成功',
                    type: 'success',
                  });
                  setTimeout(() => {
                    this.$router.go(-1);
                  }, 1000);
                })
                .catch(err => {
                  console.error(err);
                });
            } catch (e) {
              console.error(e);
            }
          } else {
            console.error('error submit!!');
            this.formValid = false;
          }
        });
      },
      radioChange: val => {
        console.log(val);
      },
    },
  };
</script>

<style scoped>
  .el-form {
    width: 450px;
    text-align: left;
  }
</style>
