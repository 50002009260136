<!-------------------------------------------------
description:  发票详情 地址信息
/**
 *@author xiaosanye 
 *@date 2023/2/11
*/
--------------------------------------------------->
<template>
  <ykc-detail-plus class="box-card" :labelWidth="120">
    <div slot="header" class="clearfix">
      <span>地址信息</span>
    </div>
    <ykc-detail-item-plus v-if="info.invoiceSituation === '1'" label="收件人">
      {{ info.consigneeName || '——' }}
    </ykc-detail-item-plus>
    <ykc-detail-item-plus v-if="info.invoiceSituation === '2'" label="收件邮箱">
      {{ info.consigneeMail || '——' }}
    </ykc-detail-item-plus>
    <ykc-detail-item-plus v-if="info.invoiceSituation === '1'" label="收件电话">
      {{ info.consigneePhone || '——' }}
    </ykc-detail-item-plus>
    <ykc-detail-item-plus v-if="info.invoiceSituation === '1'" label="收件地址">
      {{ info.consigneeAddress || '——' }}
    </ykc-detail-item-plus>
  </ykc-detail-plus>
</template>

<script>
  export default {
    name: 'AddressInfo',
    components: {},
    props: {
      info: { type: Object, default: () => ({}) },
    },
  };
</script>

<style scoped></style>
